<template>
  <v-navigation-drawer
    id="app-sidebar"
    mini-variant-width="60"
    color="#f9f9f9"
    class="pb-5"
    app
    :mini-variant.sync="drawer"
    permanent
    expand-on-hover
  >
    <v-list
      expand
      nav
    >
      <template v-for="(item, i) in navItens">
        <nav-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        />

        <nav-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
      <div />
    </v-list>
    <template v-slot:append>
      <v-list-item
        @click="loggout()"
      >
        <v-list-item-content
          class="text-start"
        >
          <v-icon
            v-if="drawer"
            color="grey"
          >
            mdi-account-arrow-left
          </v-icon>
          <v-list-item-title
            v-else
          >
            <v-icon
              class="mr-2"
            >
              mdi-account-arrow-left
            </v-icon>
            Sair
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'
import { Auth } from 'aws-amplify'

export default {
  name: 'DashboardCoreDrawer',
  components: {
    NavItem: () => import('./components/NavItem'),
    NavItemGroup: () => import('./components/NavItemGroup')
  },
  computed: {
    ...mapState(['sidebarColor']),
    drawer: {
      get () {
        return this.$store.state.drawer
      },
      set (val) {
        this.$store.commit('SET_DRAWER', val)
      }
    },
    navItens () {
      var routes = this.$router.options.routes.filter((route) => {
        return route.meta && route.meta.navItem && route
      })

      return routes
    }
  },

  methods: {
    async loggout () {
      await Auth.signOut()
      this.$cookies.remove('user')
      this.$store.commit('LOGGOUT')
      this.$router.push('/')
    }
  }
}
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  .nav-sidebar
    margin-top: 75px;

  #app-sidebar
    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
